import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import MainLayout from './pages/MainLayout';
import UserSearch from './pages/UserSearch';
import LinkSearch from './pages/LinkSearch';
import LinkForm from './pages/LinkForm';
import ProtectedRoute from './components/ProtectedRoute';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          element={
            <ProtectedRoute>
              <MainLayout />
            </ProtectedRoute>
          }
        >
          <Route path="/user-search" element={<UserSearch />} />
          <Route path="/links" element={<LinkSearch />} />
          <Route path="/link-form" element={<LinkForm onLinkSaved={() => {}} onCancel={() => {}} />} />
        </Route>
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default App;