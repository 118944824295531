import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, MenuItem, Select, InputLabel, FormControl, Typography, Grid, Tooltip, IconButton } from '@mui/material';
import { FileCopy as FileCopyIcon } from '@mui/icons-material';
import { createLink, updateLink } from '../services/auth';

export interface IMetadado {
  id?: number;
  iD_Link?: number;
  tipo_Link?: string;
  canal_Envio?: number;
}

export interface ILink {
  id?: number;
  url: string;
  shortUrl?: string;
  clicks?: number;
  metadado?: IMetadado;
}

interface LinkFormProps {
  initialLink?: ILink;
  onLinkSaved: (link: ILink) => void;
  onCancel: () => void;
}

const LinkForm: React.FC<LinkFormProps> = ({
  initialLink,
  onLinkSaved = () => {},
  onCancel = () => {}
}) => {
  const [url, setUrl] = useState('');
  const [shortUrl, setShortUrl] = useState('');
  const [tipoLink, setTipoLink] = useState('');
  const [canalEnvio, setCanalEnvio] = useState<number | string>('');
  const [responseLink, setResponseLink] = useState<string | null>(null);

  // Preenche os campos se estiver em modo de edição
  useEffect(() => {
    if (initialLink) {
      setUrl(initialLink.url);
      setShortUrl(initialLink.shortUrl || '');
      setTipoLink(initialLink.metadado?.tipo_Link || '');
      setCanalEnvio(initialLink.metadado?.canal_Envio ?? '');
      setResponseLink(initialLink.shortUrl || null);
    }
  }, [initialLink]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const linkData = {
      id: initialLink?.id,
      Url: url,
      ShortUrl: shortUrl,
      Metadado: {
        Tipo_Link: tipoLink,
        Canal_Envio: canalEnvio,
      }
    };

    try {
      if (initialLink && initialLink.id) {
        // Modo edição
        const response = await updateLink(linkData); // Implemente updateLink no serviço
        // Assumindo que a API retorna o link atualizado em um campo "result"
        setResponseLink(response.result);
        // Notifica o componente pai que o link foi salvo/atualizado
        onLinkSaved(response.result);
      } else {
        // Modo criação
        const response = await createLink(linkData);
        setResponseLink(response.result);
        onLinkSaved(response.result);
      }
    } catch (error) {
      console.error('Erro ao salvar o link:', error);
      setResponseLink(null);
    }
  };

  const handleCopyToClipboard = (link: string | undefined) => {
    if (link) {
      navigator.clipboard.writeText(link);
    }
  };

  return (
    <Box sx={{ p: 1 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        {initialLink ? 'Editar Link' : 'Criar Link'}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="URL"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              label="ShortURL"
              value={shortUrl}
              onChange={(e) => setShortUrl(e.target.value)}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Tipo Link"
              value={tipoLink}
              onChange={(e) => setTipoLink(e.target.value)}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth margin="normal">
              <InputLabel id="canal-envio-label">Canal de Envio</InputLabel>
              <Select
                labelId="canal-envio-label"
                value={canalEnvio}
                onChange={(e) => setCanalEnvio(e.target.value)}
              >
                <MenuItem value={0}>Email</MenuItem>
                <MenuItem value={1}>SMS</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
          <Button type="submit" variant="contained" color="primary">
            {initialLink ? 'Salvar Alterações' : 'Criar Link'}
          </Button>
          <Button variant="outlined" color="secondary" onClick={onCancel}>
            Cancelar
          </Button>
        </Box>
      </form>
      {responseLink && (
        <Box sx={{ mt: 3, display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ mr: 2 }}>Link Completo Gerado:</Typography>
          <Typography variant="body1" sx={{ mr: 2 }}>{responseLink}</Typography>
          <Tooltip title="Copiar para Área de Transferência">
            <IconButton color="primary" onClick={() => handleCopyToClipboard(responseLink)}>
              <FileCopyIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default LinkForm;
