import React, { useState } from 'react';
import { getLink } from '../services/auth';
import { Typography, Box, TextField, Button, List, ListItem, ListItemText, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LinkForm from './LinkForm';
import { ILink } from '../tipos/tipos'; // Caso a interface ILink seja exportada do LinkForm ou de um arquivo de tipos

const LinkSearch: React.FC = () => {
  const [shortURL, setShortURL] = useState('');
  const [links, setLinks] = useState<ILink[]>([]);
  const [editingLink, setEditingLink] = useState<ILink | null>(null);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const data = await getLink(shortURL);
      setLinks(data);
    } catch (error) {
      console.error('Erro ao pesquisar Links:', error);
    }
  };

  const handleEdit = (link: ILink) => {
    setEditingLink(link);
  };

  // Callback para atualizar a lista e limpar o estado de edição após salvar
  const handleLinkSaved = (updatedLink: ILink) => {
    // Atualiza a lista de links (pode ser refinado de acordo com a lógica de negócio)
    const updatedLinks = links.map((link) =>
      link.id === updatedLink.id ? updatedLink : link
    );
    setLinks(updatedLinks);
    setShortURL('');
    setLinks([]);
    setEditingLink(null);
  };

  return (
    <Box sx={{ p: 1 }}>
      <Typography variant="h4">Pesquisa de Links</Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <TextField
          label="Link encurtado"
          value={shortURL}
          onChange={(e) => setShortURL(e.target.value)}
          margin="normal"
          fullWidth
        />
        <Button type="submit" variant="contained" color="primary" sx={{ marginTop: '16px' }}>
          Buscar
        </Button>
      </Box>

      <List sx={{ mt: 3 }}>
        {links.map((link) => (
          <ListItem key={link.id} secondaryAction={
            <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(link)}>
              <EditIcon />
            </IconButton>
          }>
            <ListItemText
              primary={`URL: ${link.url}`}
              secondary={
                <>
                  <Typography component="span" variant="body2" color="textPrimary">
                    {`Short URL: ${link.shortUrl}`}
                  </Typography>
                  <br />
                  <Typography component="span" variant="body2" color="textPrimary">
                    {`Clicks: ${link.clicks}`}
                  </Typography>
                  <br />
                  {link.metadado && (
                    <>
                      <Typography component="span" variant="body2" color="textPrimary">
                        {`Tipo Link: ${link.metadado.tipo_Link || ''}`}
                      </Typography>
                      <br />
                      <Typography component="span" variant="body2" color="textPrimary">
                        {`Canal Envio: ${link.metadado.canal_Envio === 0 ? 'Email' : link.metadado.canal_Envio === 1 ? 'SMS' : ''}`}
                      </Typography>
                    </>
                  )}
                </>
              }
            />
          </ListItem>
        ))}
      </List>

      {/* Renderiza o formulário para edição se houver um link selecionado */}
      {editingLink && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>Editar Link</Typography>
          <LinkForm initialLink={editingLink} onLinkSaved={handleLinkSaved} onCancel={() => setEditingLink(null)} />
        </Box>
      )}
    </Box>
  );
};

export default LinkSearch;
